<template>
  <section>
    <content-header :title="$t('securityProfilesMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterSecurityProfile')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="securityProfileSearch"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getSecurityProfiles(1)"
        />
      </div>
      <CustomNotFound
        v-if="noResultsFound"
        :text="$t('noResultsFound')"
      />
      <separator
        class="my-4"
        :text="$t('listOfSecurityProfiles')"
      />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => openFormModal()"
            :text="$t('create')"
          />
        </div>
      </div>
      <div class="row">
        <div
          v-for="(securityProfile, index) in securityProfileSearchResult.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="securityProfile"
            @on-edit="openFormModal(securityProfile)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="securityProfileSearchResult.count > 0"
        id="pgPager"
        class="my-4"
        :data="securityProfileSearchResult"
        :page-size="pageSize"
        @pagination-go-page="getSecurityProfiles($event)"
        @pagination-rows-per-page="getSecurityProfiles(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showFormModal"
      :title="$t('securityProfileMaintenance')"
    >
      <SecurityProfileForm
        v-if="showFormModal"
        :value="securityProfile"
        @close="showFormModal = false"
        @submitted="getSecurityProfiles()"
      />
    </custom-modal>
  </section>
</template>
<script>

import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import CustomModal from '@/components/basics/modal/CustomModal';
import SecurityProfileForm from './components/SecurityProfileForm';
import {
  getSecurityProfileList as _getSecurityProfileList,
  getSecurityProfiles as _getSecurityProfiles
} from '@/services/SecurityProfileService';
import sessionState from '@/mixins/sessionState';

export default {
  name: 'SecurityProfilesMantaince',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
    CustomModal,
    SecurityProfileForm
  },
data() {
    return {
      securityProfiles: {
        key: [],
      },
      selectedSecurityProfiles: {
        key: [],
      },
      securityProfileSearch: { name: '' },
      securityProfileSearchResult: {
        key: []
      },
      noResultsFound: false,
      pageSize: 10,
      pageIndex: 1,
      selectedSecurityProfile: null,
      showFormModal: false,
    };
  },
  mixins: [sessionState],
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'securityProfileName',
          label: this.$t('securityProfileName'),
          component: 'BaseInput',
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'securityProfileName',
          fieldvalue: 'securityProfileId',
          clearable: true,
          class: 'col-sm-12 col-md-6 col-lg-6',
          initialValue: this.securityProfileSearch.name,
          onChanged: this.onKeyChanged,
        },
      ];
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getSecurityProfileList()
        .then((response) => {
          this.securityProfiles.key = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onKeyChanged(value) {
      if (value == null) {
        this.selectedSecurityProfiles.key = [];
        return;
      }

      this.securityProfileSearch.name = value;
    },
    async getSecurityProfiles(pageIndex = null, pageSize = null) {
      this.pageIndex = pageIndex || this.pageIndex;
      this.pageSize = pageSize || this.pageSize;

      const payload = {
        name: this.securityProfileSearch.name,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getSecurityProfiles(payload)
        .then(({ data }) => {
          this.securityProfileSearchResult = data;
        })
    },
    openFormModal(securityProfile = null) {
      this.securityProfile = securityProfile;
      this.showFormModal = true;
    },
  },
  async mounted() {
    await this.loadInitialFilters();
  },
};
</script>
<style lang="scss" scoped>
::v-deep h6 {
  color: $color-primary;
}
::v-deep textarea {
  resize: none;
}
</style>