<template>
  <div class="card pt-2 border-light security-profile-result">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-6 col-md-6 col-lg-5">
          <p>
            <label>{{ $t('securityProfileName') }}: </label>
            <span>{{ value.securityProfileName }}</span>
          </p>
          <p>
            <label>{{ $t('minimalCharacters') }}: </label>
            <span>{{ value.minimalCharacters }}</span>
          </p>
          <p>
            <label>{{ $t('validityDayPassword') }}: </label>
            <span>{{ value.validityDayPassword }}</span>
          </p>
          <p>
            <label>{{ $t('needLowerCaseLetterFlag') }}: </label>
            <span>{{ (value.needLowerCaseLetterFlag ? $t('yes') : $t('no')) || '-' }}</span>
          </p>
        </div>

        <div class="col col-sm-6 col-md-6 col-lg-5">
          <p>
            <label>{{ $t('needUpperCaseLetterFlag') }}: </label>
            <span>{{ (value.needUpperCaseLetterFlag ? $t('yes') : $t('no')) || '-' }}</span>
          </p>
          <p>
            <label>{{ $t('needNumberFlag') }}: </label>
            <span>{{ (value.needNumberFlag ? $t('yes') : $t('no')) || '-' }}</span>
          </p>
          <p>
            <label>{{ $t('needSpecialCharactersFlag') }}: </label>
            <span>{{ (value.needSpecialCharactersFlag ? $t('yes') : $t('no')) || '-' }}</span>
          </p>
          <p>
            <label>{{ $t('failedAttempsAllow') }}: </label>
            <span>{{ value.failedAttempsAllow }}</span>
          </p>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-2 pt-2">
          <action-button
            :actions="actions"
            class="float-right"
            :text="$t('actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'SecurityProfileSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit', this.value),
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.security-profile-result p {
  margin-bottom: 0.2rem;
}

.security-profile-result p i {
  cursor: pointer;
  font-size: 18px;
}

.security-profile-result label {
  font-size: 14px;
  min-width: 70px;
  font-weight: bold;
}

.security-profile-result p i,
.security-profile-result span {
  color: $color-primary;
  font-weight: bold;
  margin-left: 5px;
}

.security-profile-result .custom-control.custom-switch {
  display: inline;
  margin-left: 5px;
}
</style>
