<template>
  <div id="unlockDatabase" class="container-md">
    <content-header :title="$t('unlockDatabase')" />
    <div class="main-page-container">
      <div class="row text-center p-1 pt-4 pb-2">
        <div class="col-md-12">
          <button type="button" class="btn" @click="handleUnlockDatabase()">
            <i class="fad fa-unlock"></i>
          </button>
        </div>
        <div class="col-md-12 pt-4">
          <span class="last-process">{{ $t('lastProcess') }}</span>
        </div>
        <div class="col-md-12 pt-4">
          <span class="">{{ $t('userName') }}:&nbsp;</span>
          <label class="">{{ model.user }}</label>
        </div>
        <div class="col-md-12">
          <span class="">{{ $t('dateProcess') }}:&nbsp;</span>
          <label class="">{{ model.dateProcess }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';
import ContentHeader from '@/components/Header';
import {
  getLastUnlockProcess as _getLastUnlockProcess,
  unlockDatabase as _unlockDatabase,
} from '@/services/DatabaseService';

export default {
  name: 'UnlockDatabase',
  components: {
    ContentHeader,
  },
  data() {
    return {
      model: null,
    };
  },
  methods: {
    async handleUnlockDatabase() {
      await _unlockDatabase(this.fullProfile.userId)
        .then(() => {
          this.ShowErrorToast(this.$t('operationCompleted'));
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
          this.getLastUnlockProcess();
        });
    },
    async getLastUnlockProcess() {
      await _getLastUnlockProcess()
        .then((response) => {
          this.model = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['fullProfile']),
  },
  async mounted() {
    await this.getLastUnlockProcess();
  },
};
</script>
<style scoped>
#unlockDatabase button {
  border: 1px solid #ff0000;
  border-radius: 10px;
}
#unlockDatabase button > i {
  font-size: 7rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  color: #ff0000;
}
#unlockDatabase .last-process {
  font-weight: 900;
  font-size: 24px;
}
#unlockDatabase span {
  font-weight: 700;
  font-size: 18px;
}
#unlockDatabase label {
  font-size: 18px;
}
.fa-swap-opacity .fad:before,
.fad.fa-swap-opacity:before,
.fad:after {
  opacity: 1;
  opacity: var(--fa-secondary-opacity, 1);
}
</style>