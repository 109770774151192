<template>
  <div class="col-md-12">
    <validation-observer 
      ref="formRef" 
      novalidate
    >
      <div class="row">
        <div class="col-md-6">
          <validation-provider 
            v-slot="{ errors }"
            rules="required|max:100"
          >
            <base-input
              v-model="model.securityProfileName"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="$t('securityProfileName')"
            />
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min_value: 0"
            tag="div"
          >
            <filter-numeric
              v-model="model.minimalCharacters"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :min="0"
              :title="$t('minimalCharacters')"
            />
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min_value: 0"
            tag="div"
          >
            <filter-numeric
              v-model="model.validityDayPassword"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :min="0"
              :title="$t('validityDayPassword')"
            />
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider
            v-slot="{ errors }" 
            rules="min_value: 0"
            tag="div"
          >
            <filter-numeric
              v-model="model.failedAttempsAllow"
              :clearable="false"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :min="0"
              :title="$t('failedAttempsAllow')"
            />
          </validation-provider>
        </div>
        
        <div class="col-md-6">
          <b-form-checkbox
            v-model="model.needLowerCaseLetterFlag"
            switch
          >
            {{ $t('needLowerCaseLetterFlag') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
            v-model="model.needUpperCaseLetterFlag"
            switch
          >
            {{ $t('needUpperCaseLetterFlag') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
            v-model="model.needNumberFlag"
            switch
          >
            {{ $t('needNumberFlag') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
            v-model="model.needSpecialCharactersFlag"
            switch
          >
            {{ $t('needSpecialCharactersFlag') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-12 text-right mt-3">
          <base-filled-button
            bg-color="#707070"
            class="mx-0 float-left"
            icon-class="fas fa-ban"
            :on-click="() => $emit('close')"
            :text="$t('cancel')"
          />

          <button
            class="btn btn-outline-primary"
            variant="outline-primary"
            @click="onSubmit()"
          >
            <i class="far fa-save" /> {{ $t('save') }}
          </button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import {
  createSecurityProfile as _createSecurityProfile,
  updateSecurityProfile as _updateSecurityProfile,
} from '@/services/SecurityProfileService';

export default {
  name: 'SecurityProfileForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        securityProfileId: null,
        securityProfileName: null,
        minimalCharacters: null,
        validityDayPassword: null,
        needLowerCaseLetterFlag: false,
        needUpperCaseLetterFlag: false,
        needNumberFlag: false,
        needSpecialCharactersFlag: false,
        failedAttempsAllow: null,
        isActive: null
      },
      initialized: false
    };
  },
  mounted() {
    if (this.value && this.value.securityProfileId) this.model = { ...this.value };
    setTimeout(() => {
      this.initialized = true;
    }, 300);
  },
  methods: {
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;
      const model = {
        ...this.model,
      };
      if (!this.model.securityProfileId) {
        await _createSecurityProfile(model).then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        });
      } else {
        await _updateSecurityProfile(model).then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff8105 !important;
  background-color: #ff8105 !important;
}
</style>
